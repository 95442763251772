/* DateTimePickerStyles.css */

.custom-datetime-picker {
    width: 100%;
    background-color: #f9f9f9;
    border-radius: 5px;
}

.custom-datetime-picker .MuiFormLabel-root { 
    font-family: Inter, Helvetica, "sans-serif";
    display: none;
}

.custom-datetime-picker .MuiInput-root:hover {
    // background-color: red !important;
}

.custom-datetime-picker .MuiInputBase-root .MuiOutlinedInput-notchedOutline { 
    border: none !important;
}
.custom-datetime-picker .MuiInputBase-root { 
    .MuiInputBase-input {
        @extend .form-control; 
        @extend .form-control-solid;
        @extend .mb-3;
        @extend .mb-lg-0;
        font-weight: 500 !important; 
        color: #5e6278 !important;
        font-family: Inter, Helvetica, "sans-serif" !important;
        cursor:pointer;
    }
}

.custom-datetime-picker .MuiInput-underline:before,
.custom-datetime-picker .MuiInput-underline:after,
.custom-datetime-picker .MuiInput-underline:hover:not(.Mui-disabled):before {
    display: none;
}

.MuiButtonBase-root { 
    font-size: 1.1rem !important;
    font-family: Inter, Helvetica, "sans-serif";
    font-weight: 500 !important; 
    color: #5e6278 !important;

    &:hover { 
        background-color: #009ef7 !important;
        border-radius: 5px;
        color: white !important;
    }

    & .Mui-selected { 
        background-color: #009ef7 !important;
        color: white;

    }
    
}

.Mui-selected { 
    background-color: #009ef7 !important;
    border-radius: 5px !important;
    color: white !important;
}

.MuiPickersDay-today {
    border-radius: 5px !important;
}

.form-select.form-select-solid {
    cursor:pointer;
}