.question-list-container {
    // Default mobile view
    border: none;
}
@media screen and (min-width: 576px) {
    .question-list-container {
      border: 2px solid $border-color;
      border-radius: $border-radius !important;
    }
}
  

  
  