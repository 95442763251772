.circle { 
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: #8592a3;
    font-weight: 700;
}

.avatar { 
    position: relative;
    width: 2.375rem;
    height: 2.375rem;
    cursor: pointer;

    .avatar-initial { 
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: #8592a3;
        font-weight: 700;
    }

    .rounded-square { 
        border-radius: 25% !important;
    }
}