@import '../core/components/variables';

.nav-title { 
    color: #5e6278;


}

.nav-title:hover{
    cursor: pointer !important;
    color: #181c32;
}