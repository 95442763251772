
.table-record-name-fs { 
    font-size: 1.075rem !important;
}

.table-column-fs { 
    font-size: 0.95rem !important;
}

@media screen and (max-width: 768px) {
    .table-record-name-fs { 
        font-size: 1.5rem !important;
    }
    .table-column-fs {
        font-size: 1.075rem !important;

    }
}

